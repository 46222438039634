import React, { Component } from 'react';

class App extends Component {
    render() {
      return (
        <h1>Hello world</h1>
      );
    }
  }
  
  export default App;